<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->
        <panel
            title="Buat Surat Tanda Setoran Retribusi Daerah"
            class="panel panel-success"
        >
            <!-- Judul -->
            <div>
                <b-row>
                    <b-col md="8">
                        <h4>STSRD</h4>
                        <p>
                            <b>Surat Tanda Setoran Retribusi Daerah</b>, yang
                            selanjutnya disingkat STSRD, adalah bukti setoran
                            pembayaran retribusi yang diterima oleh Bendahara
                            Penerimaan ke RKUD (Bank SUMUT).
                        </p>
                    </b-col>
                    <b-col md="4">
                        <div class="float-right">
                            <span class="px-2">
                                <i class="fa fa-info-circle fa-2x"></i>
                            </span>
                            <span class="h4">PEMBUATAN STSRD</span>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <hr />
            <!-- end of judul -->

            <!-- form data -->
            <form
                @submit.prevent="store"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Rincian STSRD</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <b-button variant="success" @click="lihatSSRD">
                                    <span v-if="isBusy">
                                        <b-spinner
                                            variant="primary"
                                            label="Spinning"
                                            small
                                        ></b-spinner>
                                    </span>
                                    <i class="fa fa-check-circle" v-else></i>
                                    Lihat STSRD
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Table -->
                <!-- Data Table & Pagination -->
                <b-table-simple
                    hover
                    small
                    bordered
                    responsive
                >
                    <b-thead head-variant="light">
                        <b-tr>
                            <b-th width="50px">
                                No.
                            </b-th>
                            <b-th>
                                NO./TGL. STSRD
                            </b-th>
                            <b-th>
                                NO./TGL. SPTRD
                            </b-th>
                            <b-th>
                                NPWRD
                            </b-th>
                            <b-th class="text-center">
                                NOMINAL BAYAR <br>
                                (IDR)
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody v-if="this.isBusy">
                        <b-tr>
                            <b-td colspan="5">
                                <div class="text-center text-success my-2">
                                    <b-spinner class="align-middle" small></b-spinner>
                                    <strong>Loading...</strong>
                                </div>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                    <b-tbody v-else>
                        <template v-if="ssrdList.total > 0">
                            <b-tr v-for="(bendahara, index) in ssrdList.bendahara" :key="bendahara.id">
                                <b-td>
                                    {{ index + 1 }}
                                </b-td>
                                <b-td>
                                    <div>
                                        {{ bendahara.no_sts }}
                                    </div>
                                    <div>
                                        {{ bendahara.tgl_sts }}
                                    </div>
                                </b-td>
                                <b-td>
                                    <div>
                                        {{ bendahara.spt.no_spt }}
                                    </div>
                                    <div>
                                        {{ bendahara.spt.tgl_spt }}
                                    </div>
                                </b-td>
                                <b-td>
                                    <div>
                                        {{ bendahara.spt.npwrd.no_pokok }}
                                    </div>
                                    <div>
                                        {{ bendahara.spt.npwrd.nama }}
                                    </div>
                                </b-td>
                                <b-td class="text-right">
                                    {{ bendahara.nilai_terutang.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                </b-td>
                            </b-tr>
                            <b-tr v-if="ssrdList.bendahara.length" class="bg-light">
                                <b-td colspan="4" class="text-right font-weight-bold">
                                    Total pembayaran melalui Bendahara Penerimaan
                                </b-td>
                                <b-td class="text-right font-weight-bold">
                                    {{ ssrdList.total_bendahara.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                </b-td>
                            </b-tr>
                            <b-tr v-for="(qris, index) in ssrdList.qris" :key="qris.id">
                                <b-td>
                                    {{ index + 1 }}
                                </b-td>
                                <b-td>
                                    <div>
                                        {{ qris.no_sts }}
                                    </div>
                                    <div>
                                        {{ qris.tgl_sts }}
                                    </div>
                                </b-td>
                                <b-td>
                                    <div>
                                        {{ qris.spt.no_spt }}
                                    </div>
                                    <div>
                                        {{ qris.spt.tgl_spt }}
                                    </div>
                                </b-td>
                                <b-td>
                                    <div>
                                        {{ qris.spt.npwrd.no_pokok }}
                                    </div>
                                    <div>
                                        {{ qris.spt.npwrd.nama }}
                                    </div>
                                </b-td>
                                <b-td class="text-right">
                                    {{ qris.nilai_terutang.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                </b-td>
                            </b-tr>
                            <b-tr v-if="ssrdList.qris.length" class="bg-light">
                                <b-td colspan="4" class="text-right font-weight-bold">
                                    Total pembayaran melalui QRIS
                                </b-td>
                                <b-td class="text-right font-weight-bold">
                                    {{ ssrdList.total_qris.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                </b-td>
                            </b-tr>
                            <b-tr class="bg-light" v-if="ssrdList.total > 0">
                                <b-td colspan="4" class="text-right font-weight-bold">
                                    Total pembayaran (Bendahara penerimaan + QRIS)
                                </b-td>
                                <b-td class="text-right font-weight-bold">
                                    {{ ssrdList.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") }}
                                </b-td>
                            </b-tr>
                        </template>
                        <b-tr v-else>
                            <b-td colspan="5" class="text-center">
                                There are no records to show
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
                <!-- end of table -->
                <!-- end of Table -->
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold"
                        >Total Biaya Retribusi</label
                    >
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-6">
                                <b-input-group prepend="Rp.">
                                    <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Jumlah STSRD"
                                        v-model="biaya_retribusi"
                                        disabled
                                    />
                                </b-input-group>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-2 col-form-label font-weight-bold">
                        Keterangan <br />
                        (250 karakter)
                        <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-sm-10">
                                <b-form-textarea
                                    id="textarea-no-resize"
                                    placeholder="Masukkan Keterangan"
                                    rows="6"
                                    no-resize
                                    v-model="form.keterangan"
                                    :class="{
                                        'form-control': true,
                                        'is-invalid': form.errors.has(
                                            'keterangan'
                                        ),
                                    }"
                                ></b-form-textarea>
                                <em
                                    v-if="form.errors.has('keterangan')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.keterangan[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="pt-2">
                    <b-row>
                        <b-col>
                            <div>
                                <span class="px-2">
                                    <b-button
                                        variant="primary"
                                        type="submit"
                                        :disabled="simpanDisable"
                                    >
                                        <span v-if="isLoading">
                                            <b-spinner
                                                variant="primary"
                                                label="Spinning"
                                                small
                                            ></b-spinner>
                                        </span>
                                        <i class="fa fa-pencil-alt" v-else></i>
                                        Buat STSRD
                                    </b-button>
                                </span>
                                <span>
                                    <b-button
                                        variant="secondary"
                                        @click="clear"
                                    >
                                        <i class="fa fa-redo-alt"></i> Clear
                                    </b-button>
                                </span>
                            </div>
                        </b-col>
                        <b-col v-if="lihatVar">
                            <div class="float-right">
                                <b-button variant="success" @click="showPdf(no_sts)">
                                    <i class="fa fa-file-pdf"></i> Lihat STSRD
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
            </form>
            <!-- end of form-->
        </panel>
        <b-modal
            v-model="showLoader"
            id="modalLoader"
            hide-footer
            hide-header
            no-close-on-backdrop
            centered
            size="sm"
        >
            <div class="text-center">
                <b-spinner variant="light" label="Text Centered"></b-spinner>
                <h5>Harap Menunggu...</h5>
                <div>Data Anda Sedang Di Proses</div>
            </div>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "../../../components/npwr/HRetribusi.vue";
import axios from "axios";
import hapusStorage from '@/helper/hapusLocalStore'

export default {
    mixins: [hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            ssrdList: {
                bendahara: [],
                qris: [],
                total: 0,
                total_bendahara: '',
                total_qris: '',
            },
            biaya_retribusi: "",
            isBusy: false,
            sortBy: null,
            sortDesc: false,
            filter: null,
            filterBy: "all",
            pageOptions: [5, 10, 15, 25],
            perPage: 10,
            currentPage: 1,
            totalRows: 0,
            // fields
            fields: [
                {
                    key: "index",
                    label: "No.",
                    tdClass: "text-right",
                },
                {
                    key: "no_sts",
                    label: "NO./TGL STSRD",
                    sortable: true,
                },
                {
                    key: "spt",
                    label: "NO./TGL. SPTRD",
                    sortable: true,
                },
                {
                    key: "npwrd",
                    label: "NPWRD",
                    sortable: true,
                },
                {
                    key: "nilai_terutang",
                    label: "NOMINAL BAYAR \n (IDR)",
                    sortable: true,
                    tdClass: "text-right",
                    thClass: "text-center",
                },
            ],
            form: new Form({
                keterangan: "",
            }),
            errors: [],
            isLoading: false,
            simpanDisable: false,
            lihatVar: false,
            showLoader: false,
            pdfUrl: "",
            no_sts: ''
        };
    },
    computed: {
        stsVal () {
            return this.no_sts
        }
    },
    methods: {
        lihatSSRD() {
            this.isBusy = true;
            axios
                .get("/api/transaksi/ssr/bukti-bayar/cash")
                .then((response) => {
                    this.isBusy = false;
                    const items = response.data.data;
                    console.log(items)
                    this.ssrdList = {
                        bendahara: items.bendahara,
                        qris: items.qris,
                        total: items.nilai.total,
                        total_bendahara: items.nilai.bendahara,
                        total_qris: items.nilai.qris
                    }
                    this.biaya_retribusi = this.ssrdList.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        store () {
            this.isLoading = true;
            this.form
                .post("/api/transaksi/sts")
                .then((response) => {
                    this.isLoading = false;
                    this.simpanDisable = true;
                    this.lihatVar = true;
                    this.no_sts = response.data.data.no_sts
                    this.$swal({
                        title: "Data Berhasil Dibuat",
                        text:
                            "Untuk melihat STSRD yang sudah dibuat, silahkan lihat pada halaman List STSRD.",
                        icon: "success",
                        showCloseButton: true,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    this.isLoading = false;
                    this.simpanDisable = false;
                    this.lihatVar = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        clear() {
            this.form.reset();
            this.form.clear();
            this.simpanDisable = false;
            this.lihatVar = false;
            this.ssrdList = [];
            this.biaya_retribusi = "";
            this.no_sts = ""
        },
        showPdf(id) {
            this.getPdf(id);
        },
        // close modal
        closeModal() {
            this.$root.$emit("bv::hide::modal", "modal-1");
            this.showModalData = false;
            this.pdfUrl = null;
        },
        // view pdf
        getPdf(id) {
            this.showLoader = true
            axios
                .get("/api/view-pdf/surat/sts/by-sts/" + id, {
                    responseType: "arraybuffer",
                    timeout: 10000,
                })
                .then((response) => {
                    this.showLoader = false
                    this.pdfUrl = window.URL.createObjectURL(
                        new Blob([response.data], { type: "application/pdf" })
                    );
                    window.open(this.pdfUrl, '_blank')
                })
                .catch((error) => {
                    this.showLoader = false
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
    },
};
</script>

<style scoped>
.custom-border {
    border-top: 1px solid rgb(78, 77, 77);
}
</style>
